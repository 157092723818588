#root.localhost {
    
}

#root.testrecercatirmuorg .login .card , #root.recercatirmuorg .login .card{
    background-color: #E5B549;
}
#root.localhost .login .card input {
    
}

#root.testentitatssifaccat { }
#root.entitatssifaccat { }

#root .login .card {
    box-shadow: 0px 20px 20px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

#root .login .card input {
    padding: 10px 10px;
}


header.appBar {
	background-color: #ffffff;
	color: #5E5E5E;
	/*opacity: 0.8;*/
	box-shadow: none;
}
.appMenu {
	padding-top: 20px;
	min-height: 10px !important;
	background-color: #ffffff;
	color: #5E5E5E;
	box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}
.appMenu a {
	min-width: 150px;
}


.recercat .appBar { background-color: #E5B549; }
.recercat .appBar .appTitleContainer { display: none; }
.recercat .appBar img.imgLogo { margin-bottom: 10px; border:0px; margin-left: 10px; margin-top: 3px; }
.recercat .appMenu { background-color: #F5DBA3; }
.recercat .rc-tree-node-selected { background-color: #E5B549!important; }


a.appMenuNew {
	    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    max-width: 360px;
    min-width: 150px;
    position: relative;
    min-height: 48px;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 12px 16px;
    overflow: hidden;
    white-space: normal;
    text-align: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    color: rgba(0, 0, 0, 0.6);
}
a.appMenuNew:hover {
	background-color: #ffffff;
}
a.appMenuNew[aria-current="page"] {
  color: #1976d2;
	background-color: #ffffff;
	border-bottom: 2px solid #1976d2;
}
a.appMenuNew.selected {
    color: #1976d2;
    background-color: #ffffff;
    border-bottom: 2px solid #1976d2;
}

img.imgLogo {
    
    max-width: 215px;
    max-height: 50px;
    margin-right: 15px;
    padding-right: 15px;
    border-right: 2px solid #efefef;
    vertical-align: middle;
	
}

.imgLogoContainer {
    padding-top: 5px;
    padding-left: 10px;
    line-height: 50px;
}
.appTitleContainer {
    padding-top: 15px;
    padding-left: 5px;
}

.iconWrapper {  
  width: 80px;
}

.table-quotes-periods td, .table-quotes-periods th  {
	padding: 6px 4px 6px 4px;;
}

.remittanceStatusIcon {
	padding: 0px !important;
}

.remittanceExpand {
	background-color: #FFEDDD;
	padding: 30px;
}

.remittanceExpandTable thead tr th {
	background-color: #FFEDDD;
}
.remittancesButtonSEPA {
	margin-left: 20px !important;
}
.remittancesButtonSEPA span span {
	margin-right: 0px;
}

.checkboxGroupInputCompacted div {
	margin-top: 10px;
}
.checkboxGroupInputCompacted div label span {
	padding-top: 0px;
	padding-bottom: 0px;
}
.nElements0 {
	display: none !important;
}

.grey2 {
	opacity: 0.2;
}
.grey4 {
	opacity: 0.4;
}
.grey6 {
	opacity: 0.6;
}
.grey8 {
	opacity: 0.8;
}
.grey9 {
	opacity: 0.9;
}

h5 span {
	font-size: 2rem !important;
}

.gridWebCamContainer {
	width: 100% !important;
	margin: 0px !important; 
}
.gridWebCam {
	padding: 0px !important;
}
.photo_box {
    position: relative;
}
button.btnPhotoUser {
	min-width: 10px;
        position: absolute;
        right: 25px;
        top: 0px;        
}
button.btnPhotoUser.btnDeletePhoto {
    right: 0px;
}
button.btnPhotoUser.btnWebcam {
    right: 50px;
}

.photo_box div {
    display: block;
    margin-bottom: 20px;
    line-height: 200px;
}

.users_show .MuiGrid-root.MuiGrid-item, .usersClassroomsExpand .MuiGrid-root.MuiGrid-item {
    padding-top: 0px!important;
}
.users_show .MuiGrid-root.MuiGrid-container, .usersClassroomsExpand .MuiGrid-root.MuiGrid-container {
    margin-top: 0px!important;
}

.users_show_photo_container {
	overflow: hidden;
}

.titleUserShow {
	margin-top: 20px !important;
}

.users_remittances_list {
	margin-top: 10px;	
}

div.users_show_families_chip {
	margin-right: 5px;
	margin-bottom: 5px;
	cursor: 'inherit';
}

a.users_show_families_chip {
	text-decoration: none !important;
	cursor: 'inherit';
}

.users_show_family_title {
	margin-top: 10px !important;
	
}
.users_show_bank_payer {
	margin-bottom: 5px !important;
}
.users_show_social_nets_datagrid th {
	height: 2px;
	font-size: 1px;
	border-bottom: 0px;
}
.users_show_social_nets_datagrid td {
	border-bottom: 0px;
}
.users_show_social_nets_datagrid .avatar {
	width: 30px;
	height: 30px;
}
.users_show_social_nets_datagrid a {
	text-decoration: none !important;
	cursor: 'inherit';
}
.header_grey6 thead {
	opacity: 0.6;
}

img.docs_photo_user {
	max-height: 500px;
        vertical-align: middle;
        width: 100%;
}
img.docs_card {
	max-height: 3000px;
        vertical-align: middle;
        width: 100%;
}
.pdfFieldContainer {
    text-align: center;
}
.pdfFieldContainer iframe {
    width: 95%; 
    height: 900px;
    border: none;
    background-color: #ffffff;
}

img.docs_photo_list {
	max-height: 80px;
	max-width: 150px;
}

img.docs_logo_add_member {
	max-height: 100px;
	max-width: 300px;
}

.dashboard_box {
	margin-top: 20px;
}

.dashboard_subscription_data {
	font-size: 13px !important;
}

.dashboard_chart_members text.recharts-cartesian-axis-tick-value tspan {
	font-size: 12px;
}

.usersEconomicDataExpand {
	background-color: #E7EAFF;
	padding: 30px;
}

.usersEconomicDataExpand thead tr th {
	background-color: #E7EAFF;
}

.usersClassroomsExpand {
	background-color: #FFFFEB;
	padding: 30px;
}

.usersClassroomsExpand thead tr th {
	background-color: #FFFFEB;
}


.users_economic_data_form div div {
    padding-top: 0px !important;   
    margin-top: 0px;
}
.users_economic_data_form div div div input {
    padding-top: 15px !important;
}
.users_economic_data_form div div p {
    margin-top: 15px;    
}
.users_economic_data_form {
    padding-top: 20px;
}
.settings_box {
    margin: auto;
    margin-top: 30px;    
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.settings_box.not_available {
    background-color: #FFE1DD;
}

.groups_title {
    margin-left: 20px!important;
    margin-top: 10px!important;
}

.last_num_inserted {
    
}
.last_num_inserted999999999 {
    display: none;
}
.last_num_inserted999999998 {
    display: none;
}

.user_form_toolbar {
    flex: 1;
    display: flex;
    justify-content: space-between;
}

.settings_emails_richtext {
    
    height: 400px;
    
}

.signup_box {
    margin: auto;
}

.add_member_box {
    margin: auto;
    margin-top: 30px;    
}

.add_member_box.recercat .MuiFilledInput-root {
    background-color: rgb(0,0,0,0.05);
}


.add_member_box.recercat .MuiSwitch-colorPrimary.Mui-checked {
    color: #D8884E;
}
.add_member_box.recercat .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #D8884E;
    opacity: 0.3;
}

.add_member_box.recercat .MuiButton-containedPrimary {
    color: #fff;
    background-color: #D8884E;
}

.MuiButton-containedPrimary.btn-action {
    color: #fff;
    background-color: #D8884E;
}
.MuiButton-containedPrimary.btn-action:hover {
    color: #fff;
    background-color: #B46124;
}

.button-blue {
    color: #3f51b5!important;
}

.add_member_box.recercat .add_member_button {
    width: 100%;
    text-align: center;
}
.add_member_box.recercat .MuiFormLabel-root.Mui-focused {
    color: #D8884E;    
}
.add_member_box.recercat .MuiFilledInput-underline:after {
    border-bottom: 2px solid #D8884E;
}

.add_member_box.recercat .little {
    font-size: 12px;
}

.add_member_box.recercat h1 {
    font-size: 25px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 40px;
    padding-top: 0px;
}

.add_member_box.recercat h2 {
    font-size: 15px;
    text-align: left;
    margin-top: 0px;
    margin-bottom: 20px;
    padding-top: 0px;
}

.add_member_box.recercat .th {
    background-color: #efefef;
}

.add_member_box.recercat .btn-error {
    background-color: rgba(255, 0, 0, 0.2)!important;
}

.add_member_box.recercat .btn-error:hover {
    background-color: rgba(255, 0, 0, 0.4)!important;
}

.add_member_box.recercat .MuiButton-outlined {
    border: 1px solid rgba(255, 0, 0, 0.23);
    padding: 5px 15px;
}

.add_member_box.recercat .btn-green {
    background-color: #A9DDAD;
}
.add_member_box.recercat .btn-green:hover {
    background-color: #88CE8D;
}

.justify {
    text-align: justify;
}
    
.add_member_box div .add_member_input {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.add_member_box div label {
    font-size: 0.9rem;
}
.add_member_input label span {
    font-size: 0.9rem;
}
.add_member_box .add_member_input_container {
    margin-top: 0px!important;
    margin-bottom: 0px!important;
}
.add_member_lopd {
    font-size: 0.8rem;
    margin-top: 0px;
    margin-bottom: 20px;
    text-align: justify;
    border: 0px solid #808080;
    color: #808080;
    padding: 5px;
    width: 100%;
}
.settings_add_member_lopd textarea {
    font-size: 0.9rem;
}
.add_member_form_sent {
    text-align: center;
    width: 100%;
}

.add_member_button {
    width: 100%;
    text-align: right;
}

a[href="#/groups-tree-contacts/create"] {
  display: none;
}
#root .recercat a[href="#/groups-tree/create"] {
  display: none;
}
#root .recercat .rc-tree-title {
    padding: 5px 10px;
}
#root .recercat .rc-tree-title button {
    display: none;
}
#root .recercat .rc-tree-switcher {
    display: none;
}

.contacts_fac_header {
    margin-top: 20px;
}
.my-search {
    border: 1px solid #888888;
    padding: 5px;
}

img.classrooms_list_item {
    max-height: 160px; 
    max-width: 100%;
}

a.classrooms_list_item_link {
    text-decoration: none;
    color: #000000;
}

img.classrooms_carrousel_item {
    height: 400px;    
}
div.classrooms_carrousel_item_container {
    width: 100%;
    height: 400px;
} 
div.donations_carrousel_item_container {
    width: 100%;
    height: 460px;
}
div.events_carrousel_item_container {
    width: 100%;
    height: 500px;
}
img.events_carrousel_item {
    /*max-height: 500px;   */
    max-width: 100%;
    max-height: 100%;
    //width: 100%;
}
img.donations_carrousel_photos {
    max-width: 100%;
    max-height: 460px;
}
.classrooms_carrousel_photos, .events_carrousel_photos {
    text-align: center;
    overflow: hidden;
}

.classrooms_list_item_container {
    overflow: hidden;
    padding: 10px;
}
.classrooms_requests_description {
    text-align: justify;
}

.classrooms_doc_link {
    width: 100%; 
    text-align: right;
}
.mt-0 {
    margin-top: 0px !important;
}
.mt-10 {
    margin-top: 10px !important;
}
.mt-20 {
    margin-top: 20px !important;
}
.mt-30 {
    margin-top: 30px !important;
}
.mr-0 {
    margin-right: 0px !important;
}
.mr-5 {
    margin-right: 5px !important;
}
.mr-10 {
    margin-right: 10px !important;
}
.mr-20 {
    margin-right: 20px !important;
}
.mr-30 {
    margin-right: 30px !important;
}
.ml-0 {
    margin-left: 0px !important;
}
.ml-10 {
    margin-left: 10px !important;
}
.ml-20 {
    margin-left: 20px !important;
}
.ml-30 {
    margin-left: 30px !important;
}
.mb-0 {
    margin-bottom: 0px !important;
}
.mb-5 {
    margin-bottom: 5px !important;
}
.mb-10 {
    margin-bottom: 10px !important;
}
.mb-20 {
    margin-bottom: 20px !important;
}
.mb-30 {
    margin-bottom: 30px !important;
}

.filterCalendar {
    margin-top: 30px!important;
}

.event_button_delete {
    right: 20px!important;
    position: absolute!important;
    color: #f44336 !important;
}

.generate_repetitive {
    background-color: #E7EBFF !important;
    padding-left: 20px !important;
}

.generate_repetitive.mt10 {
    margin-top: 10px !important;
}

.events_labels {
    background-color: #FFFFE7 !important;
    margin-top: 10px !important;
    padding-left: 20px !important;
}

.red {
    color: #ff0000;
}

.mr-2 { text-decoration: none; margin-right: 10px; color: #000000; }

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.chip {
    margin: 4px;
    cursor: 'inherit';
}

.chip.green {
    background-color: #D9FFDD;
}

.chip.red {
    background-color: #FFADAD;
}

.chip.orange {
    background-color: #FFC291;
}

.divider-mb {
    margin-bottom: 15px !important;
}
.accordion > div:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.simple-iterator-inline li section {    
    display:flex;
    flex-direction:row;
}

.simple-iterator-inline section > div > div {
    margin-right: 8px;
}

.simple-iterator-multiline section > div > div {
    margin-right: 8px;
    float: left;
}



.birthdays-list {
    padding-top: 0px!important;
    padding-bottom: 0px!important;
    padding-left: 0px!important;
    padding-right: 0px!important;
}

.map {
    text-align: center;
}

.map svg{
    font-size: 12px;
    font-weight: bold;
    overflow: visible;
}

.map svg .zone {
    cursor: pointer;
}

.map svg .tooltip {
    fill:#000000;
    font-size: 14px;
    font-weight: normal;
}

.map svg g text {
    font-size:7px;
    fill:#FFFFFF;
}
.map svg g .markPoint {
    font-size:12px;
}
               
g#zone-1 polygon, g#zone-1 path { fill:#A2B0DB; stroke:#4C76BA; stroke-width:0.783; stroke-miterlimit:3.864; }
g#zone-2 polygon, g#zone-2 path { fill:#CFA3C2; stroke:#B8709F; stroke-width:0.783; stroke-miterlimit:3.864; }
g#zone-3 polygon, g#zone-3 path  { fill:#93CFCB; stroke:#00A79F; stroke-width:0.783; stroke-miterlimit:3.864; }
g#zone-4 polygon, g#zone-4 path { fill:#55ADD1; stroke:#0087B7; stroke-width:0.783; stroke-miterlimit:3.864; }
g#zone-5 polygon, g#zone-5 path { fill:#B5CD86; stroke:#78AF33; stroke-width:0.783; stroke-miterlimit:3.864; }
g#zone-6 polygon, g#zone-6 path { fill:#A1D3E2; stroke:#46B5CD; stroke-width:0.783; stroke-miterlimit:3.864; }
g#zone-7 polygon, g#zone-7 path { fill:#EAA5B2; stroke:#DC5E7F; stroke-width:0.783; stroke-miterlimit:3.864; }
g#zone-8 polygon, g#zone-8 path { fill:#F9BE89; stroke:#EE7402; stroke-width:0.783; stroke-miterlimit:3.864; }

g.zone.hover { opacity: 0.6; }


.bt-2-grey {
    border-bottom: 2px solid #efefef;
    padding-left: 10px;
}
.form-100 { width: 100px !important; }
.form-150 { width: 150px !important; }
.form-200 { width: 200px !important; }
.form-250 { width: 250px !important; }
.form-300 { width: 300px !important; }

.form-clear { clear: left }

.color-input {
    margin-top: -19px !important; 
    margin-bottom: 0px !important;
}
.color-input-cards {
    margin-top: 8px !important; 
}

fieldset {
    border: 0px !important;
}

.custom-field-title {
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
}


.Header.recercat {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-size: cover;
  padding: 1rem;
  background-color: #D8884E;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

@media (min-width: 599px) {
    .Header.recercat .Header_logo {        
        width: auto;
        margin: auto;
    }
}

@media (max-width: 599px) {
    .Header.recercat .Header_logo {        
        width: 360px;
        margin: auto;
    }
}


.pt-0 {
    padding-top: 0px !important;
}

.mr-10 {
    margin-right: 15px !important;
}

.groups_cards td.column-font_color div div{
    border: 1px solid #000000;
    border-radius: 5px;
}
.groups_cards td.column-font_color div span{
    display: none;
}

.text-right {
    text-align: right;
}

.calendar-filter-select {
    width: 220px;
}

@media (max-width: 500px) {
.table-container-recercat-centers {
    overflow: hidden !important;
}

table.recercat-centers {
    table-layout: fixed; /* Fes que la taula mantingui la seva amplada */
    width: 100%; /* Amplada màxima per a la taula */
}
table.recercat-centers td, table.recercat-centers th  {
    padding: 2px 3px;
    font-size: 12px;
    width: 100%; /* Amplada màxima per a cada cel·la de la taula */
    white-space: normal; /* Permet que el contingut ocupe dues línies */
    word-wrap: break-word; /* Fes que les paraules s'ajustin per a la segona línia si és necessari */
  }
  table.recercat-centers td button {
      margin-top: 2px;
      margin-bottom: 2px;
      font-size: 12px;
      padding: 2px 2px;
  }
}

.public-events-filter-mt {
    margin-top: 5px !important;
}

.public-events-img {
  width: 100%;
  height: 200px;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
.public-events-img.item {
    height: 100% !important;
}

.pd-5 {
    padding: 5%;
}

.p-5 {
    padding: 5px !important;
}
.p-10 {
    padding: 10px !important;
}
.p-20 {
    padding: 20px !important;
}

.public-events-description {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 6; /* Número de línies a mostrar */
  -webkit-box-orient: vertical;
  white-space: normal;
}

.bold {
    font-weight: bold !important;
}

.fs-10 {
    font-size: 10px !important;
}
.fs-11 {
    font-size: 11px !important;
}
.fs-12 {
    font-size: 12px !important;
}
.fs-14 {
    font-size: 14px !important;
}
.fs-16 {
    font-size: 16px !important;
}
.fs-18 {
    font-size: 18px !important;
}
.fs-20 {
    font-size: 20px !important;
}
.upper {
    text-transform: uppercase;
}
.capitalize {
    text-transform: capitalize;
}
.bg-orange {
    background-color: #ff6600 !important;
}
.bg-grey div {
    background-color: #F4F4F4 !important;
}
.bc-orange {
    border-color: #ff6600 !important;
}
.color-white {
    color: #ffffff !important;
}
.text-align-left {
    text-align: left !important;
}
.ml-5 {
    margin-left: 5px !important;
}

.public-events-icon {
    font-size: 18px !important;
}

.public-events-back-button svg {
    font-size: 2rem !important;
}
.events-calendar-icon svg {
    font-size: 1.3rem;
}
.events-calendar-icon a {
    text-decoration: none;
    color: #ffffff;
}
.signup-lopd {
    font-size: 12px;
}

.signup_box .MuiFormHelperText-root.Mui-error span, .signup_box .MuiFormHelperText-root span {
    display: none;
}

.text-center {
    text-align: center;
}

.cursor {
    cursor: pointer !important;
}

.bg-dark {
    background-color: #555555!important;
}
.bg-green {
    background-color: #0DA705!important;
}

.align-right {
    text-align: right;
}

.justify-right {
    justify-content: flex-end;
}

#mainLoading {
    display: none;
}
.loading {
  position: absolute;
  top: 0px;
  z-index: 9999998;
  width: 100%;
  height: 100%;
  background-color: #efefef;
  opacity: 0.2;
  display: flex;
  justify-content: center; 
  align-items: center;   
}
.loading div {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #1976d2;
    border-bottom: 16px solid #1976d2;
    width: 100px;
    height: 100px;
    text-align: center;
    -webkit-animation: spin 1.2s linear infinite;
    animation: spin 1.2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.alert_bagde span {
    background-color: #FF8181;
}
.alerts_item {
    border-bottom: 1px solid #f0f0f0;
    background-color: #FFE7E7;
}
.alerts_item.new {
    background-color: #FFC0C0;
}
.alerts_item_link {
    cursor: pointer;
}
.alerts_item:hover {
    background-color: #FF8181;    
}
.alerts_delete_all {
    width: 95%;
    height: 40px;
    text-align: right;
    line-height: 40px;
    font-size: 15px;
    padding-right: 5%;
}
.alerts_no_results {    
    height: 60px;
    text-align: center;
    line-height: 60px;
    font-size: 15px;        
}
#long-menu ul {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.align-items-center {
    line-height: 100%!important;
    height: 100%!important;
    margin: auto!important;
}

.donations_linear_progress {
    height: 15px !important;
    border-radius: 13px !important;
}
.ff-roboto {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
}
.lh-30 {
    line-height: 30px;
}


.donations_projects .donations-header {
    background-color: #ff9000;
    padding-top: 30px;
    padding-bottom: 25px;
}
.donations_projects .donations-header .header-left {
    padding-left: 10%;
}
.donations_projects .donations-header .header-right {    
    font-size: 25px;
    color: white;
    padding-top: 40px;
}

.donations_projects .donations-footer {
    background: #f60;
    color: #fff;
    padding-top: 0 !important;
    margin-top: 0 !important; 
}

.donations_projects .footer-center {
    padding: 20px 0px;
    text-align: center;
}

.donations_projects .footer-right {
    font-weight: 700;
    color: #fff;
    background: #000;
    margin: 0;
    font-size: 22px;
    float: right;
    padding: 15px;
}


.donations_projects .copyright {
    float: right;
    min-height: 22px;
    line-height: 20px;
    margin: 20px 20px 0 20px;
    padding-top: 3px;
}

.donations_projects .adrecafooter {
    line-height: 20px;
    margin: 20px 20px 20px 0;
}

.donations_projects .donations-footer a {
    color: #ffd1b3 !important;
    margin-right: 10px;
    margin-left: 10px;
    text-decoration: none;
}

.donations_projects .donations-footer .footer-right a {
    color: white !important;
}

.donations_projects .donations-footer a:hover {
    color: #fff !important;
}

.donations_projects .back_button_container button {
    float: left;
    margin-left: 5%;
}
.donations_projects .back_button_container div.title {
    font-size: 22px;
    padding-top: 27px;
    color: rgba(0, 0, 0, 0.8);    
    float: left;
}

.donations-buttons-1 {
    color: #1976d2 !important;
    font-size: 16px !important;
}

.donations-buttons-2 {
    color: #ffffff !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
    padding: 6px 15px !important;
    border-radius: 8px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 200 !important;
    font-size: 16px !important;
    background-color: #1976d2 !important;
}

.donations_cards {
    background-color: #fbfbfb !important;
}

.donations_cards_comments {
    background-color: #FFF6EB !important;
}


.my-pagination button {
    border-radius: 20px !important;
}

.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem; /* Puedes ajustar el valor según sea necesario */
}

.donations-form {
    
}
.donations-form-radio {
    flex-direction: row !important;
}
.donations-form-pt-16 {
    padding-top: 16px !important;
}
.donations-form-pt-0 {
    padding-top: 10px !important;
}
.donations-form-pt-10 {
    padding-top: 20px !important;
}
.donations-form input {
    padding: 5px 10px;
}
h1 {
    font-size: 30px !important;
}
.donations_association_image {
    padding-right: 20px;
}

.donations-video {
    position: relative;
    width: 100%; /* Amplada de l'espai disponible */
    padding-bottom: 56.25%; /* Proporció 16:9 (alçada/amplada) */
    overflow: hidden;
    margin-bottom: 50px;
}
  
.donations-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}